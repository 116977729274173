.adserve{
    height: 40vh;
    background-image: url('../../assets/Rectangle 7.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    flex-direction: column;
    align-items: center;  
    justify-content: space-around;
    padding: 50px 20px;
}
.text{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: white;
}
@media(min-width: 768px){
    .text{
        font-size: 24px;
        font-weight: 800;
        line-height: 40px;
        padding: 30px;
    }
}

@media(min-width: 1024px){
    .text{
        font-weight: 800;
        font-size: 24px;
        line-height: 51px;
        padding: 0;
    }
    .adserve{
        height: 300px;
    }

}
@media(min-width: 1500px){
    .text{
        font-size: calc(24px + 1vw);
        line-height: calc(51px + 1.3vw);
    }
    .btn{
        font-size: calc( 20px + 0.5vw);
    }
    .adserve{
        height: 30vh;
    }

}

@media screen and (orientation:landscape) and (max-width: 900px){
    .adserve{
        height: 200px;
    }
    .text{
        line-height: 25px;
    }
    .btn{
        margin-top: 1%;
        font-size: 14px ;
    }
}