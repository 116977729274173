
.nav{
  padding-top: 1rem/* 8px */;
  padding-bottom: 1rem/* 8px */;
  display: flex;
  justify-content: flex-end;
}

.navbars{
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-between;
  margin-left: 40%;
}

.navbars.active{
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: space-between;
}


.navItemsDiv{
  display: flex;
  justify-content: space-between;
  width: 50%;
  font-size: 16px;
  font-weight: 700;
  color: #535252;
  font-family: 'Open Sans', "sans serif";
}

.navItems:hover{
  --tw-text-opacity: 1;
  color: rgb(81 221 17 / var(--tw-text-opacity));
}

.joinUsBtn{
  --tw-bg-opacity: 1;
  background-color: rgb(81 221 17 / var(--tw-bg-opacity));
  height: 40px;
  width: 119px;
  border-radius: 5px;
  margin-right: 10%;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 16px;
  font-weight: 700;
}



@media screen and (max-width:767px){
  .nav {
    background-color: rgb(197, 194, 194);
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
  }
  
  .nav.active {
    left: 0;
    transition: 350ms;
    z-index: 15;
    width: 70%;
  }

  .navbars{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 60%;
  }

  .navItemsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    height: 70%;
    margin-right: 50%;
  }



  .joinUsBtn{
    width: 100px;
    margin-right: 50%;

  }
}


@media (orientation: landscape) {
  .carousel {
    height: 300px;
  }
  .nav-menu{
    height: 100vh;
  }
  .smallNav{
    display: none;
  }
  .largeNav{
    display: flex;
  }
}
@media screen and (orientation:landscape) and (max-width: 900px){
  .container{
      height: 400px;
  }
  .joinus{
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      background-color: brown;
  }
}
