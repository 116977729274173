.feedback{
    width: 100%;
    height: 600px;
    background: url(../../assets/Desktop3.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.feedbackOverlay{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
}
@media(min-width: 768px){
  .feedback{
    height: 800px;
  }
}

@media(min-width: 1200px){
   .feedback{
      height: 900px;
      background-color: red;
   }
}
@media(min-width: 1500px){
   .feedback{
      height: 100vh;
   }
}