.green{
    background:url(../../assets/Rectangle\ 43.png);
    background-repeat:no-repeat;
    background-size:cover; 
    background-position:center;
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}



.article{
   font-size: 20px ;
}

.videodiv{
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),url(../../assets/laptop-coworking.png);
  background-repeat:no-repeat;
  background-size:cover; 
  background-position:center;
  margin-top: 5vh;
  border-radius: 0.5vw;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 50vh;
  width:90%

}



@media screen and (min-width: 768px){
    .green{
      height: 40vh;
      margin-top: 0;
    }
  }
  
  @media screen and (min-width: 1024px){
     .green{
      height:400px;
     }
     .videodiv{
      width: 70vw;
      height: 70%; 
     }
     
  }
  @media screen and (min-width: 1200px){
     .green{
      height:40vh;
     }
     .videodiv{
      width: 70vw;
      height: 70%; 
     }
     
  }

  @media screen and (min-width:1500px){
    .learnMore{
      font-size: calc(40px + 1vw) ;
    }
    .aboutAdserve{
      font-size: calc(30px + 1vw);
    }
    .article {
      font-size: calc(20px + 0.5vw);
      line-height: calc(36px + 1vw);
    }
    .btn{
      font-size:calc(16px + 0.5vw);
    }
  }

  @media screen and (min-width: 1200px) {
      .videodiv{
        height: 70%;
        width: 55vw;
    }

  }
 
  @media screen and (orientation:landscape) and (max-width: 900px){
    .green{
      margin-top: 0;
    }
    .announceCont{
      height: 600px;
    }
    .videodiv{
      height: 90vh; 
     }
}