@media screen and (min-width:1500px){
    .head{
        font-size: calc(36px + 1.1vw) ;
    }
    .subhead{
        font-size: calc(24px + 1.1vw);
    }
    .cardHead{
        font-size: calc(16px + 0.2vw);
        line-height: calc(24px + 1vh);
    }
    .cardText{
        font-size: calc(16px + 0.2vw);
        line-height: calc(25px + 1vh);
    }
  }

  @media screen and (min-width:1800px) {
    .cardHead{
        font-size: calc(16px + 0.3vw);
    }
    .cardText{
        font-size: calc(16px + 0.3vw);
    }
  }
  @media screen and (orientation:landscape) and (max-width: 900px){
    .adserveCard{
        width: 40%;
    }
}
